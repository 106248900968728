<template>
  <v-dialog v-model="showing" max-width="550px">
    <v-card>
      <v-card-title class="headline" primary-title>
        Opret agency
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Fornavn"
          v-model="agency.first_name"
          name="Fornavn"
          v-validate="'required'"
          :error-messages="errors.collect('Fornavn')"
        ></v-text-field>

        <v-text-field
          label="Efternavn"
          v-model="agency.last_name"
          name="Efternavn"
          v-validate="'required'"
          :error-messages="errors.collect('Efternavn')"
        ></v-text-field>

        <v-text-field
          label="Email"
          v-model="agency.email"
          name="Email"
          v-validate="'required|email'"
          :error-messages="errors.collect('Email')"
        ></v-text-field>

        <v-text-field
          label="Adgangskode"
          v-model="agency.password"
          name="Adgangskode"
          v-validate="'required'"
          :error-messages="errors.collect('Adgangskode')"
        ></v-text-field>

        <v-text-field
          label="Agency navn"
          v-model="agency.name"
          name="navn"
          v-validate="'required'"
          :error-messages="errors.collect('navn')"
        ></v-text-field>

        <v-text-field
          label="Kontrakt URL"
          name="URL"
          v-validate="'required'"
          :error-messages="errors.collect('URL')"
          v-model="agency.contract_url"
        ></v-text-field>

        <v-text-field
          label="Antal virksomheder"
          v-model="agency.business_slots"
          name="virksomheder"
          v-validate="'required'"
          :error-messages="errors.collect('virksomheder')"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="loading" @click="save()">
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  props: {
    value: {
      required: true,
      default: false
    }
  },
  data: () => ({
    loading: false,
    agency: {}
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("agency", ["createAgency"]),
    save() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.loading = true;
          let params = {
            ...this.agency
          };
          params.business_slots = Number(params.business_slots);

          this.createAgency(params)
            .then(agency => {
              this.setSnackSuccess("Oprettet");
              this.agency = {};
              this.showing = false;
              this.loading = false;

              this.$emit("success", agency);
            })
            .catch(() => {
              this.setSnackError("Fejl");
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="text-right mb-4">
      <v-btn color="primary" class="mr-0" @click="createAgencyDialog = true">
        Opret agency
      </v-btn>
    </div>
    <create-agency-dialog
      v-model="createAgencyDialog"
      @success="loadAgencies()"
    ></create-agency-dialog>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Agencies</div>
          <span class="subtitle-1 light-grey"></span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="agencies" hide-default-footer>
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.uuid">
                <td>
                  {{ item.user.first_name }} {{ item.user.last_name }}
                  <br />
                  {{ item.name }}
                </td>
                <td>
                  {{ item.businesses.length }} / {{ item.business_slots }}
                </td>
                <td class="text-right">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <a v-on="on" :href="item.contract_url" target="_blank">
                        <v-btn color="primary" outlined icon>
                          <v-icon small>fal fa-file-signature</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    <span>Se kontrakt</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CreateAgencyDialog from "../dialogs/CreateAgencyDialog";
import { mapActions } from "vuex";
export default {
  components: { CreateAgencyDialog },
  data: () => ({
    createAgencyDialog: false,
    agencies: [],
    headers: [
      { text: "Navn", sortable: false },
      { text: "Virksomheder", sortable: false },
      { text: "", sortable: false }
    ]
  }),
  methods: {
    ...mapActions("core", ["getAgencies"]),
    loadAgencies() {
      this.getAgencies({ first: 100 }).then(agencies => {
        this.agencies = agencies;
      });
    }
  },
  created() {
    this.loadAgencies();
  }
};
</script>
